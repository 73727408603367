import React, {Component} from 'react';

export class HomeResources extends Component {
  render() {
    return(
        <div className = "homeResources">
            <div className = "folder">
                <div className = "wow fadeIn resourcesText">
                    <div className = "textTitle">Resources</div>
                    <div className = "textSubtitle">Slides, examples, templates, and more!</div>
                    <div className = "normText">
                        New materials that we present at each meeting will be posted after the event, 
                        so check our resources page for regular updates. These serve only as supplementary 
                        materials to support your learning.
                    </div>
                    <div className = "normText">
                        If you have any questions, or would like to receive some additional help, 
                        feel free to contact us and one of our execs will reach out.
                    </div>
                </div>
                <div className = "resourcesMedia">
                    <img src = "./images/icons/intro_slides.png" alt = "slides"></img>
                    <a href = "/materials" className = "wow pulse contentButton yellowButton">Released Materials</a>
                </div>
            </div>
        </div>
    );
  }
}