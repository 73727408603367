import React, {Component} from 'react';

const eventName = [
    "Intro to UTEFA",
    "Resume & Networking",
    "Fall Stock Pitch",
    "DCF Workshop"
];

const resourceType = [
    "workshop slides",
    "workshop slides",
    "stock pitch info",
    "workshop slides"
    // "pitch template"
]

const link = [
    "./resources/Intro to UTEFA.pdf",
    "./resources/Networking Resume.pdf",
    "./resources/Fall Stock Pitch.pdf",
    "./resources/DCF Guide.pdf"
];

const thumbnail = [
    "./resources/intro_thumbnail.png",
    "./resources/resumes_networking_thumbnail.png",
    "./resources/fall_pitch_thumbnail.png",
    "./resources/dcf_thumbnail.png"
]

const date = [
    "September 12, 2024",
    "September 26, 2024",
    "October 10, 2024",
    "October 17, 2024"
]

const color = [
    "borderBlue",
    "borderPink",
    "borderYellow",
    "borderNavy"
    // "borderPink",
    // "borderNavy"
]

export class Materials extends Component {
    render() {
      return(
        <div className="materials">
            <hr className = "line"></hr>
            <div className = "materialsContent">
                {eventName.map((name, i) => 
                    <div className = "wow fadeInUp resource">
                        <a href={link[i]} download>
                            <img src={thumbnail[i]} alt="download" className = {color[i]}></img>
                        </a>
                        <div className = "resourceText">
                            <a href = {link[i]} className="textHeading" download>{name}</a>
                            <div className = "subText">{resourceType[i]}</div>
                            <div className = "normText">{date[i]}</div>     
                        </div>                                
                    </div>
                )}
            </div>
            <hr className = "line"></hr> 
        </div>
      );
    }
  }